<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement space clear-details-page">
            <div class="container-960">
                <div class="bg-white p-0">
                    <div v-if="loading" class="loading">
                        <skeletonLoader />
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="() => goBack()"></button>
                    <div class="newsLabel" v-if="Object.keys(showNews).length > 0"
                        :class="(Object.keys(showNews).length > 0) ? 'show' : ''">
                        <div class="card border-0 mb-4">
                            <div class="card-body">
                                <h4 class="card-title"><a href="javascript:void(0)">{{showNews[0].title}}</a> <span
                                        class="modifyDate">{{formatDate(showNews[0].created)}}</span></h4>
                                <img :src="imageLink+'/get/doc?type=TRADE&doc='+showNews[0].document+'&id='+showNews[0].user_id"
                                    class="img-fluid mixedTrade" alt="" />
                                <div
                                    class="d-flex align-showNews[0]s-center justify-content-between ideas-currency-row my-3">
                                    <div class="forexnood d-flex align-showNews[0]s-center"><p class="IdeaUsername mb-0">{{showNews[0].firstname.charAt(0) || ''}} {{showNews[0].lastname.charAt(0) || ''}}</p> <span
                                            class="mx-2">{{showNews[0].firstname || ''}} {{showNews[0].lastname ||
                                            ''}}</span> <!-- <img src="assets/images/badge.jpg" alt="" /> --></div>
                                    <div class="currency-wrap">
                                        <span class="currency"
                                           ><a
                                                href="javascript:void(0)">{{showNews[0].symbol || 'N/A'}}</a></span>
                                        <span class="monthly"
                                           ><a
                                                href="javascript:void(0)">{{showNews[0].time_interval ||
                                                'N/A'}}</a></span>
                                        <span class="short"
                                        >
                                            <a href="javascript:void(0)"
                                                :class="SymbolClass[showNews[0].position.toLowerCase()]"><i
                                                    data-feather="arrow-down-right"></i> {{showNews[0].position ||
                                                    'N/A'}}</a>
                                        </span>
                                    </div>
                                </div>
                                <p>{{showNews[0].description}}</p>
                                <div class="smallbtn d-flex align-showNews[0]s-center"><span class="cardEelement"
                                       >{{showNews[0].classification
                                        || 'N/A'}}</span> <span class="cardEelement"
                                       >{{showNews[0].type || 'N/A'}}</span></div>
                                <div
                                    class="btm-row d-flex align-showNews[0]s-center justify-content-between border-top mt-4 pt-3">
                                    <div @click="() => likeIdea(showNews[0],'like')">
                                        <span><i class="pointer" :class="parseInt(showNews[0].is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'" aria-hidden="true"></i> {{showNews[0].likes_count
                                        ||
                                        0}}</span>
                                    </div>
                                    <div class="righticons">
                                        <span class="pointer" @click="() => likeIdea(showNews[0],'comment')"><i class="fa fa-comments-o"
                                                aria-hidden="true"></i>{{showNews[0].comment_count
                                                || 0}}</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i>
                                            {{showNews[0].share_count || 0}}</span> <span><i class="fa fa-bookmark-o"
                                                aria-hidden="true"></i> {{showNews[0].bookmarks_count || 0}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="commentArea" v-if="showComment">
                    <div class="IdeaComments" v-if="store.ideaComments.totalRecords ">
                        <div class="card p-3 flex-row border-0 mb-2" v-for="item,index in store.ideaComments.records">
                            <div class="flex-shrink-0">
                                <span class="IdeaUsername mb-0">{{item.firstname.charAt(0) || ''}}{{item.lastname.charAt(0) || ''}}</span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="mb-1 medium">{{item.firstname || ''}} {{item.lastname || ''}}</h6>
                                <p class="lighttext">{{formatCommentDate(item.created)}}</p>
                                <p>{{item.comment}}</p>
                                <div class="d-flex align-items-center replies">
                                    <p class="mb-0 d-inline-flex align-items-center medium me-4" @click="() => callUpdateTrade(item,'likeComment')">
                                        <i class="me-1 pointer" :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'"></i>{{item.like_count}} Likes
                                    </p>
                                    <!-- <p class="mb-0 d-inline-flex align-items-center medium">
                                        <vue-feather class="me-1" size="19px" type="message-circle">
                                        </vue-feather> 2 Replies
                                    </p> -->
                                    <!-- <p class="mb-0 d-inline-flex align-items-center"><vue-feather size="19px" type="heart"></vue-feather> 4 Likes</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-3 flex-row justify-content-center border-0 mb-2" v-else>
                        <div class= "noComments">No comments yet!</div>
                    </div>
                    <div class="col-12 text-center my-4"
                        v-if="store.ideaComments.records.length < store.ideaComments.totalRecords">
                         <p class="grd_btn" v-if="store.loading"><i class="me-2 fa fa-spinner fa-spin"></i></p>
                        <a href="javascript:void(0)" class="grd_btn" @click="loadMore()" v-else> Load More</a>
                    </div>
                    <Form class="login position-sticky bottomZero p-3 bg-white" @submit="commentIdea">
                        <div class="card-body py-3 px-0 border-0 mb-2">
                            <div class="commentBox form-group w-100">
                                <Field as = "textarea" class="form-control" name="comment" rules="required"
                                    v-model="comment" placeholder="Add comment here"/>
                                <ErrorMessage as="paragraph" name="comment" class="text-danger mb-0" />
                            </div>
                                <div class="d-flex align-items-center justify-content-end">
                                    <a href="javascript:void(0)" class="border-btn green me-2" @click="
                                        showComment = false;comment = ''">Cancel</a>
                                    <button class="grd_btn ">Submit</button>
                                </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
        <login v-if="showPopup"></login>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import skeletonLoader from "../../components/shared/skeletonLoader";
import { useMainStore } from "@/store";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import login from "../../components/shared/userlogin"
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            loading: false,
            SymbolClass: {
                'neutral': 'yellowBG',
                'buy': 'greenBG',
                'sell': 'redBG'
            },
            imageLink: process.env.VUE_APP_API_BASE_URL,
            comment : '',
            showComment : false,
            showPopup : '',
            page : 1,
            perPage : 10
        }
    },
    methods: {
        loadMore(){
            this.page = parseInt(this.page)+1
            this.getComments()
        },
        commentIdea(values, { resetForm }){
            if(Object.keys(this.showNews).length){
                this.showNews[0].comment_count = parseInt( this.showNews[0].comment_count)+1
               }
            let form = {
                "trade_idea_id" : this.showNews[0].trade_id, "parent_id" : 0, "user_id" : this.store.user.profile.user_id, "comment" : this.comment
            }
            this.store.addComment(form, true, this).then(() => {
              resetForm();
            })
            .catch(() => {
            });
        },
        likeIdea(item,type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if(type == 'like'){
                    this.callUpdateTrade(item,'')
                }else{
                    this.showComment = true
                }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item,type) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if(type && type == 'likeComment'){
                form['type'] = 'COMMENT'
                form['target_id'] = item.id
            }
            if(parseInt(item.is_like) == 1){
                form['is_like'] = 0
            }
            form['page'] = 'IdeaDetail'
            if(form['type'] != 'COMMENT'){
                if(parseInt(this.showNews[0].trade_id) == parseInt(item.trade_id)){
                    if(parseInt(this.showNews[0].is_like) == 1){
                        this.showNews[0].is_like = 0
                        this.showNews[0].likes_count = parseInt(this.showNews[0].likes_count)-1
                    }else{
                        this.showNews[0].is_like = 1
                        this.showNews[0].likes_count = parseInt(this.showNews[0].likes_count)+1
                    }
                }
            }else if(form['type'] == 'COMMENT'){
                if(this.store.ideaComments.totalRecords){
                  this.store.ideaComments.records.forEach(val =>{
                    if(parseInt(val.id) == parseInt(item.id)){
                     if(parseInt(val.is_like) == 1){
                        val.is_like = 0
                        val.like_count = parseInt( val.like_count)-1
                      }else{
                        val.is_like = 1
                        val.like_count = parseInt( val.like_count)+1
                      }
                    }

                  })
                }
            }
            this.store.callUpdateIdeas(form, true)
        },
        goBack() {
            this.$router.back()
        },
        formatCommentDate(val){
            return moment(val).fromNow();
        },
        formatDate(val) {
            return moment(val).format('DD MMM')
        },
        calIdeaDetail(id) {
            let form = {
                'trade_id': id
            }
            this.store
                .callTradeIdeasDetail(form, false)
                .then((response) => {
                    this.showNews = response
                    this.loading = false
                })
                .catch(() => this.loading = false);
        },
        getComments(){
            let form = {
                "trade_id":this.$route.params.id,
                "page":this.page,
                "perPage":this.perPage,
                "sort" : {"field" : "trade_idea_comments.created", "type" : "DESC"},
            }
            if(this.store.user.profile){
                form["logged_user_id"] = this.store.user.profile.user_id
            }
            this.store.getIdeasComments(form, true)
        }
    },
    created() {
        if (this.$route.params.id) {
            this.loading = true
            this.calIdeaDetail(this.$route.params.id);
        } else {
            this.$router.push('/trade-ideas')
        }
        this.getComments()
    },
    components: {
        bannerSlider: bannerslider,
        skeletonLoader,
        Form,
        Field,
        ErrorMessage,
        login
    },
}
</script>
<style scoped>
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>